<template>
    <div class="block w-full overflow-x-auto">
        <table class="items-center w-full border-collapse">
            <thead>
                <tr class="px-2 border-b-2">
                    <th
                        v-if="$store.state.account.accountType === AccountTypeEnum.Owner"
                        class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"></th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                        Tipo
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                        Categoria
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                        Subcategoria
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                        Monto
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                        Fecha Gasto
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                        Fecha Creado
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                        Observación
                    </th>
                    <th
                        v-if="isPending"
                        class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td
                        v-if="$store.state.account.accountType === AccountTypeEnum.Owner"></td>
                    <td>
                        <div class="sm:col-start-1">
                            <el-select placeholder="Filtro" v-model="search.ExpenseType" clearable @change="onLoad">
                                <el-option :value="ExpenseType.Static" :label="ExpenseTypesDict[ExpenseType.Static]">
                                    {{ ExpenseTypesDict[ExpenseType.Static] }}
                                </el-option>
                                <el-option :value="ExpenseType.Dynamic" :label="ExpenseTypesDict[ExpenseType.Dynamic]">
                                    {{ ExpenseTypesDict[ExpenseType.Dynamic] }}
                                </el-option>
                                <el-option :value="ExpenseType.Product" :label="ExpenseTypesDict[ExpenseType.Product]">
                                    {{ ExpenseTypesDict[ExpenseType.Product] }}
                                </el-option>
                            </el-select>
                        </div>
                    </td>
                    <td>
                        <div class="sm:col-start-1">
                            <el-select placeholder="Filtro" v-model="search.ExpenseCategory" clearable @change="onLoad">
                                <el-option :value="ExpenseCategory.Salary"
                                    :label="ExpenseCategoryDict[ExpenseCategory.Salary]">
                                    {{ ExpenseCategoryDict[ExpenseCategory.Salary] }}
                                </el-option>
                                <el-option :value="ExpenseCategory.Rent"
                                    :label="ExpenseCategoryDict[ExpenseCategory.Rent]">
                                    {{ ExpenseCategoryDict[ExpenseCategory.Rent] }}
                                </el-option>
                                <el-option :value="ExpenseCategory.Services"
                                    :label="ExpenseCategoryDict[ExpenseCategory.Services]">
                                    {{ ExpenseCategoryDict[ExpenseCategory.Services] }}
                                </el-option>
                                <el-option :value="ExpenseCategory.Accounting"
                                    :label="ExpenseCategoryDict[ExpenseCategory.Accounting]">
                                    {{ ExpenseCategoryDict[ExpenseCategory.Accounting] }}
                                </el-option>
                                <el-option :value="ExpenseCategory.Marketing"
                                    :label="ExpenseCategoryDict[ExpenseCategory.Marketing]">
                                    {{ ExpenseCategoryDict[ExpenseCategory.Marketing] }}
                                </el-option>
                                <el-option :value="ExpenseCategory.Other"
                                    :label="ExpenseCategoryDict[ExpenseCategory.Other]">
                                    {{ ExpenseCategoryDict[ExpenseCategory.Other] }}
                                </el-option>
                                <el-option :value="ExpenseCategory.Product"
                                    :label="ExpenseCategoryDict[ExpenseCategory.Product]">
                                    {{ ExpenseCategoryDict[ExpenseCategory.Product] }}
                                </el-option>
                            </el-select>
                        </div>
                    </td>
                    <td>
                        <div class="sm:col-start-1">
                            <el-input placeholder="Filtro" v-model="search.ExpenseSubcategory"
                                @change="handleSubcategoryChange">
                            </el-input>
                        </div>
                    </td>
                    <td></td>
                    <td>
                        <div class="sm:col-start-1">
                            <el-date-picker v-model="search.ExpenseDate" type="date"
                                :format="$store.state.business.dateFormat" class="w-full" @change="onLoad">
                            </el-date-picker>
                        </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="isPending"></td>
                </tr>
                <tr v-for="expense in entities" :key="expense.id">
                    <td
                        v-if="$store.state.account.accountType === AccountTypeEnum.Owner"
                        class="p-2 border-t w-12">
                        <sa-group-action-button @edit="onEdit(expense)"
                            @delete="onDelete(expense.id)"></sa-group-action-button>
                    </td>
                    <td class="p-2 border-t w-32">
                        {{ ExpenseTypesDict[expense.expenseType] }}
                    </td>
                    <td class="p-2 border-t w-28">
                        {{ getExpenseCategory(expense) }}
                    </td>
                    <td class="p-2 border-t w-28">
                        {{ getExpenseSubcategory(expense) }}
                    </td>
                    <td class="p-2 border-t w-28">
                        {{ moneyFormat(expense.amount) }}
                    </td>
                    <td class="p-2 border-t w-40">
                        <el-date-picker v-model="expense.expenseDate" type="date" disabled
                            :format="$store.state.business.dateFormat" class="w-full bg-select-button">
                        </el-date-picker>
                    </td>
                    <td class="p-2 border-t w-40">
                        <el-date-picker v-model="expense.createdAt" type="date" disabled
                            :format="$store.state.business.dateFormat" class="w-full bg-select-button">
                        </el-date-picker>
                    </td>
                    <td class="p-2 border-t">
                        <el-popover placement="left" width="250" trigger="click">
                            <div v-if="expense.expenseType === ExpenseType.Product">
                                <div class="horizontal-scroll-container">
                                    <div class="card-container">
                                        <div v-for="product of expense.products" :key="product.id" class="card">
                                            <div class="card-content">
                                                <span><strong>Categoría: </strong>{{ getProductSubcategory(product)
                                                    }}</span>
                                                <span><strong>Producto: </strong>{{ product.name }}</span>
                                                <span><strong>Costo: </strong>{{ moneyFormat(product.cost) }}</span>
                                                <span><strong>Cantidad: </strong>{{ product.quantity }}</span>
                                                <span><strong>Observaciones: </strong>{{ product.observations }}</span>
                                                <span><strong>Referencia: </strong>{{ product.reference }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="flex flex-col">
                                    <span class="font-bold">Observaciones</span>
                                    <span>{{ expense.observations }}</span>
                                </div>
                                <div class="flex flex-col">
                                    <span class="font-bold">Referencia</span>
                                    <span>{{ expense.reference }}</span>
                                </div>
                            </div>
                            <el-button slot="reference" type="text" class="text-left">
                                <span>Ver</span>
                                <i class='bx bx-chevron-down'></i>
                            </el-button>
                        </el-popover>
                    </td>
                    <td v-if="isPending" class="p-2 border-t w-40">
                        <el-button type="primary" icon="el-icon-money" @click="onPay(expense.id)">
                            Realizar Pago
                        </el-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import _ from 'lodash';
import numbers from '@/utils/numbers';
import {
    ExpenseType,
    ExpenseTypesDict,
    ExpenseCategory,
    ExpenseCategoryDict,
    AccountTypeEnum,
} from '@/constants';

export default {
    name: 'ExpenseList',
    props: {
        entities: {
            type: Array,
            required: true,
        },
        isPending: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            ExpenseType,
            ExpenseTypesDict,
            ExpenseCategory,
            ExpenseCategoryDict,
            AccountTypeEnum,
            search: {
                ExpenseType: null,
                ExpenseCategory: null,
                ExpenseSubcategory: null,
                ExpenseDate: null,
            },
        };
    },
    methods: {
        onLoad() {
            this.$emit('on:load', this.search);
        },
        handleSubcategoryChange(value) {
            this.search.subcategory = value;
            this.onLoad();
        },
        onEdit(expense) {
            this.$emit('on:edit', expense);
        },
        onDelete(expenseId) {
            this.$emit('on:delete', expenseId);
        },
        getExpenseCategory(expense) {
            if (expense.expenseType === ExpenseType.Product) {
                const { products } = expense;
                const productCategory = this.$store.state.catalogs.product.categories.find((pc) => pc.id === products[0].category);
                return productCategory?.name;
            }

            return ExpenseCategoryDict[expense.expenseCategory];
        },
        getExpenseSubcategory(expense) {
            if (expense.expenseType === ExpenseType.Product) {
                const { products } = expense;
                return products[0].name;
            } else {
                const { expenseSubcategory } = expense;

                if (_.isNil(expenseSubcategory)) {
                    return 'N/A';
                }

                const staticSubcategory = this.$store.state.expenses.subcategories.find((sub) => sub.id === expenseSubcategory);
                if (!_.isNil(staticSubcategory)) {
                    return _.get(staticSubcategory, 'name', 'N/A');
                }

                const productCategory = this.$store.state.catalogs.product.categories.find((pc) => pc.id === expenseSubcategory);
                if (productCategory) {
                    return productCategory.name;
                }

                return expenseSubcategory;
            }
        },
        getProductSubcategory(product) {
            const category = this.$store.state.catalogs.product.categories.find((pc) => pc.id === product.category);
            return category?.name;
        },
        moneyFormat(value) {
            const { currencySymbol } = this.$store.state.account;
            return numbers.moneyFormat(value, currencySymbol);
        },
        onPay(expenseId) {
            this.$emit('on:pay', expenseId);
        },
    },
}
</script>